import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Navbar as NavbarBs } from "react-bootstrap";

function DropdownMenu() {
  const [navLinks, setNavLinks] = useState([]);

  useEffect(() => {
    const navs = [
      { name: <strong>BUTIK</strong>},
      { name: <span className="compact-text">- Alla potensmedel</span>, path: "/butik/" },
      { name: <span className="compact-text">- Sildenafil</span>, path: "/produkter/sildenafil/" },
      { name: <span className="compact-text">- Tadalafil</span>, path: "/produkter/tadalafil/" },
      { name: <span className="compact-text">- Vardenafil</span>, path: "/produkter/vardenafil/" },
      { name: <strong>FAQ</strong>, path: "/faq" },
      { name: <strong>OM OSS / KONTAKT</strong>, path: "/omoss" },
    ];
    setNavLinks(navs);
  }, []);
  
  return (
    <NavbarBs>
      <button
        type="button"
        className="btn btn-dark dropdown-toggle"
        data-bs-toggle="dropdown"
        data-bs-display="static"
        aria-expanded="false"
      >
        Meny
      </button>
      <ul className="dropdown-menu dropdown-menu-end">
        {navLinks.map((d, i) => (
          <li key={i}>
            <Link to={d.path}>
              <button className="dropdown-item" type="button">
                {d.name}
              </button>
            </Link>
          </li>
        ))}
      </ul>
    </NavbarBs>
  );
}

export default DropdownMenu;
