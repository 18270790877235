import React from "react";
import { Helmet } from "react-helmet";
import { Erbjudande } from "../home/filter/Erbjudande";

const erbjudande = () => {
  return (
    <>
      <Helmet>
        <title>Black Week & Black Friday Erbjudanden – Köp Potensmedel Online från Sverige!</title>
        <meta 
          name="description" 
          content="Upptäck fantastiska Black Week och Black Friday erbjudanden på potensmedel. Köp receptfritt från Sverige med snabb leverans." 
        />
        <meta 
          name="keywords" 
          content="Black Week potensmedel, Black Friday potensmedel, potensmedel erbjudanden, billiga potensmedel, köpa potensmedel online, Black Friday deals, Black Week deals"
        />
        <link rel="canonical" href="https://viagrashop.se/erbjudande" />

        {/* Open Graph */}
        <meta property="og:title" content="Black Week & Black Friday Erbjudanden – Köp Potensmedel Online från Sverige!" />
        <meta 
          property="og:description" 
          content="Upptäck fantastiska Black Week och Black Friday erbjudanden på potensmedel. Köp receptfritt från Sverige med snabb leverans." 
        />
        <meta property="og:url" content="https://viagrashop.se/erbjudande" />
        <meta property="og:type" content="website" />
        
        {/* Twitter Card */}
        <meta name="twitter:title" content="Black Week & Black Friday Erbjudanden – Köp Potensmedel Online från Sverige!" />
        <meta 
          name="twitter:description" 
          content="Upptäck fantastiska Black Week och Black Friday erbjudanden på potensmedel. Köp receptfritt från Sverige med snabb leverans." 
        />
        <meta name="twitter:url" content="https://viagrashop.se/erbjudande" />
        <meta name="twitter:card" content="summary" />

        {/* X-Content-Type-Options */}
        <meta http-equiv="X-Content-Type-Options" content="nosniff" />
      </Helmet>
      <main>
        <article className="main-container">
          <section className="text-center">
            <h2>BLACKWEEK & BLACK FRIDAY</h2>
            <h3>Kommer snart</h3>
          </section>
          <section className="container-section-products">
            <Erbjudande />
          </section>
        </article>
      </main>
    </>
  );
}

export default erbjudande;
